import React, { useState, useEffect } from "react";
import { makeStyles, Typography, Button, Grid, Link, Modal, Backdrop, Fade } from "@material-ui/core";
import picture from "../api/background/modelaLanding.png";

import useGlobalStyle from "../component/style/globalStyle";
import BusinessIcon from '@material-ui/icons/Business';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';

const useStyles = makeStyles((theme) => {
  return {
    page: {
      background: "#f9f9f9",
      width: "100%",
      padding: theme.spacing(3),
    },
    root: {
      display: "flex",
    },

    toolbarIcon: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: "0 8px",
      ...theme.mixins.toolbar,
    },
    active: {
      background: "#f4f4f4",
    },
    title: {
      fontWeight: "bold",
      color: "#fbb116",
      marginBottom: theme.spacing(5),
    },
    titleWhite: {
      fontWeight: "bold",
      color: "#fff",
    },
    submit: {
      background: "linear-gradient(45deg, #F29C19 30%, #fbb116 90%)",
      borderRadius: "30px",
      boxShadow: "0 3px 5px 2px rgba(251, 177, 22, .3)",
      padding: "10px",
      paddingLeft: theme.spacing(10),
      paddingRight: theme.spacing(10),
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
      color: "white",
      fontSize: "18px",
      margin: theme.spacing(90),
    },
    submitButtonContainer: {
      position: "relative",
      width: "100%",
      textAlign: "center",
      bottom: 0,
      left: 0,
      right: 0,
      // marginBottom: "2rem", // 2rem ห่างจากขอบล่าง
    },
    titleimg: {
      margin: "auto",
      height: "8vmin",
    },
    appAvatar: {
      marginLeft: theme.spacing(2),
      color: "#ec407a",
    },
    menuButton: {
      marginRight: 30,
    },
    mainFeaturedPost: {
      position: 'relative',
      backgroundImage: "url(" + picture + ")",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      height: `calc(100vh - 64px)`, // 64px is the height of the app bar
    },
    overlay: {
      position: "absolute",
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      background: "linear-gradient(rgba(0,0,0,.1), rgba(0,0,0,.9) 93%)",
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      maxWidth: '600px',
      textAlign: 'center',
    },
  };
});

function Home() {
  const classes = useStyles();
  const globalStyle = useGlobalStyle();
  const [open, setOpen] = useState(false);

  // ใช้ useEffect เพื่อเปิด Modal ทันทีที่คอมโพเนนต์ถูกโหลด
  useEffect(() => {
    setOpen(true);
  }, []);

  // ฟังก์ชันปิด Modal
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
        <div className={classes.mainFeaturedPost}>
          <div className={classes.overlay} />
          <Grid container>
            <Grid item md={12}></Grid>
            <Grid item md={12}>
              <div className={classes.submitButtonContainer}>
                <Button
                  variant="contained"
                  className={globalStyle.buttonHome}
                  classes={{ root: globalStyle.buttonHome, contained: globalStyle.buttonHover }}
                  startIcon={<BusinessIcon />}
                  href="https://modela.co.th"
                  target="_blank"
                  style={{ marginTop: '80vh' }}
                >
                  Go to Company
                </Button>
                <Button
                  variant="contained"
                  className={globalStyle.buttonHome}
                  classes={{ root: globalStyle.buttonHome, contained: globalStyle.buttonHover }}
                  startIcon={<ShoppingCartIcon />}
                  href="https://www.store.modela.co.th/"
                  target="_blank"
                  style={{ marginTop: '80vh' }}
                >
                  Go to Shopping
                </Button>
              </div>
            </Grid>
          </Grid>
        </div>

        {/* Modal แจ้งเตือน */}
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <div className={classes.paper}>
              <h2 id="transition-modal-title">แจ้งเตือน!!</h2>
              <p id="transition-modal-description">
                WebDashboard นี้เป็นเวอร์ชันเก่าของ Modela IoT ขณะนี้มีเวอร์ชันใหม่พร้อมให้บริการแล้วที่ <strong><a href="https://www.modelaaiot.com" target="_blank" rel="noopener noreferrer">Modela AIoT</a></strong> ซึ่งมาพร้อมฟีเจอร์ที่อัปเกรดเพื่อการใช้งานที่ง่ายขึ้น สะดวก และมีประสิทธิภาพมากกว่า!
                ท่านสามารถอัปเกรดเพื่อใช้งานเวอร์ชันใหม่ได้ทันที โดยเว็บไซต์นี้จะให้บริการถึงวันที่ <strong>15 มกราคม 2568</strong> หากต้องการดูวิธีการอัปเกรด<br></br>กรุณาดูรายละเอียดด้านล่าง:
              </p>
              <p><strong>ดูวิธีการอัปเกรดและการติดตั้งแอปใหม่ได้ที่:</strong></p>
              <p>ติดตั้งแอปใหม่ได้ที่: Redirect to App Store <strong><a href="https://modela.co.th/applink.html" target="_blank" rel="noopener noreferrer">[Click]</a></strong></p>
              <p>Video แนะนำแอปใหม่ <strong><a href="https://www.youtube.com/watch?v=DIKcfkvXZFI&t=11s" target="_blank" rel="noopener noreferrer">[Click]</a></strong></p>
              <p>Video ขั้นตอนการเชื่อมต่อ WiFi <strong><a href="https://www.youtube.com/watch?v=_tf9RSTZyWo" target="_blank" rel="noopener noreferrer">[Click]</a></strong></p>
              <p>Video วิธีการอัปเกรดไปใช้แอปใหม่ <strong><a href="https://www.youtube.com/watch?v=Oawh7oQcyuo" target="_blank" rel="noopener noreferrer">[Click]</a></strong></p>
              <p>
                เมื่อเชื่อมต่ออุปกรณ์กับแอปใหม่สำเร็จแล้ว ท่านสามารถเข้าใช้งาน <strong>New WebDashboard</strong> ได้ที่ <strong><a href="https://www.modelaaiot.com" target="_blank" rel="noopener noreferrer">Modela AIoT</a></strong> และทำการเข้าสู่ระบบด้วยบัญชีเดียวกับที่สมัครใช้งานในแอปมือถือ
              </p>
              <p>
                <strong>สำคัญ!</strong> แอปเก่าจะยังคงใช้งานได้จนถึง <strong>28 กุมภาพันธ์ 2568</strong> และ WebDashboard เก่านี้จะให้บริการถึงวันที่ <strong>15 มกราคม 2568</strong> ดังนั้น เพื่อให้ท่านไม่พลาดทุกฟีเจอร์ใหม่ที่พร้อมตอบโจทย์การใช้งาน แนะนำให้อัปเกรดได้ตั้งแต่วันนี้!
              </p>
              <p>
                <strong>หมายเหตุ:</strong> ข้อมูลเก่าของท่านยังสามารถเข้าถึงได้ในแอปและ WebDashboard เก่า หากต้องการเก็บข้อมูลย้อนหลัง แนะนำให้ส่งออกข้อมูลเป็นไฟล์ Excel ก่อนถึงวันสิ้นสุดการสนับสนุนในวันที่ <strong>28 กุมภาพันธ์ 2568</strong>
              </p>
              <Button variant="contained" color="secondary" onClick={handleClose}>
                ปิดข้อความแจ้งเตือน
              </Button>
            </div>
          </Fade>
        </Modal>
    </>
  );
}
export default Home;
