import React, { useEffect, useState } from "react";
import axios from "axios";
import './TestSocket.css';

function TestSocket({ socket }) {
  const [switches, setSwitches] = useState({ switch1: false, switch2: false });
  const [status, setStatus] = useState({ Status: 'offline' });
  const [values, setValues] = useState({
    mainValue: [
      { valueNo: 1, valueData: 0 },
      { valueNo: 2, valueData: 0 },
      { valueNo: 3, valueData: 0 },
      { valueNo: 4, valueData: 0 },
      { valueNo: 5, valueData: 0 },
      { valueNo: 6, valueData: 0 },
      { valueNo: 7, valueData: 0 },
      { valueNo: 8, valueData: 0 },
    ],
    secondValue: [
      { valueNo: 1, valueData: 0 },
      { valueNo: 2, valueData: 0 },
      { valueNo: 3, valueData: 0 },
      { valueNo: 4, valueData: 0 },
      { valueNo: 5, valueData: 0 },
      { valueNo: 6, valueData: 0 },
      { valueNo: 7, valueData: 0 },
      { valueNo: 8, valueData: 0 },
    ]
  });
  const [roomName, setRoomName] = useState('');
  const [sensorSelections, setSensorSelections] = useState([
    { SensorNo: 1, SensorSelect: 0 },
    { SensorNo: 2, SensorSelect: 0 },
    { SensorNo: 3, SensorSelect: 0 },
    { SensorNo: 4, SensorSelect: 0 },
    { SensorNo: 5, SensorSelect: 0 },
    { SensorNo: 6, SensorSelect: 0 },
    { SensorNo: 7, SensorSelect: 0 },
    { SensorNo: 8, SensorSelect: 0 },
  ]);
  const [devices, setDevices] = useState([]); // สำหรับเก็บข้อมูลอุปกรณ์ที่เชื่อมต่อ [ { id: 1, name: "Device 1" }, { id: 2, name: "Device 2" }
  const [user, setUser] = useState("modela.mobileapp@gmail.com"); // สำหรับเก็บข้อมูลผู้ใช้ที่เข้าสู่ระบบ [ { id: 1, name: "User 1" }, { id: 2, name: "User 2" } ]

  // Dummy sensor options
  const sensorOptions = [
    { id: 0, name: "No Control" }, // สำหรับเมื่อไม่ได้เลือกเซ็นเซอร์ใด ๆ
    { id: 1, name: "Air Temperature J1" },
    { id: 2, name: "Air Temperature J2" },
    { id: 3, name: "Air Temperature J3" },
    { id: 4, name: "Average Air Temperature 3 Points" },
    { id: 5, name: "Air Humidity J1" },
    { id: 6, name: "Air Humidity J2" },
    { id: 7, name: "Air Humidity J3" },
    { id: 8, name: "Average Air Humidity 3 Points" },
  ];
  

  const toggleSwitch = (switchNumber) => {
    setSwitches(prev => ({ ...prev, [`switch${switchNumber}`]: !prev[`switch${switchNumber}`] }));
    const newStatus = !switches[`switch${switchNumber}`] ? 1 : 0;

    axios.post("http://localhost:8080/api/v2/control/relay", {
      key: roomName,
      switchRelay: switchNumber,
      statusRelay: newStatus
    }).then(response => {
      console.log('Success:', response);
    }).catch(error => {
      console.error('Error:', error);
    });
  };

  const handleSensorSelection = (sensor, value) => {
    setSensorSelections(prev => ({ ...prev, [sensor]: value }));

    const sensorNo = sensor;
    console.log('Sensor selection:', sensorNo, value)

    axios.post("http://localhost:8080/api/v2/control/sensor", {
      key: roomName,
      sensorNO: sensorNo,
      sensorSelect: value,
    }).then(response => {
      console.log('Sensor selection success:', response);
    }).catch(error => {
      console.error('Sensor selection error:', error);
    });
  };

  const getDevice = async () => {
    try {
      const response = await axios.get(`http://localhost:8080/api/v2/device/${user}`);
      setDevices(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const handleSensorData = (data) => {
      // console.log('receiveSensorData : ', data);
      setValues(data);
    };

    const handleStatusSensorData = (data) => {
      console.log('receiveStatusSensorData : ', data);
    }

    const handleStatusData = (data) => {
      console.log('receiveStatusData : ', data);
      setStatus(prev => {
        const newState = { ...prev };
        Object.keys(data).forEach(key => {
          if (newState.hasOwnProperty(key)) {
            newState[key] = data[key];
          }
        });
        return newState;
      });
    };

    const handleRelayData = (data) => {
      console.log('receiveRelayData : ', data);
    }

    socket.on('receiveSensorData', handleSensorData);
    socket.on('receiveStatusSensorData', handleStatusSensorData);
    socket.on('receiveStatusData', handleStatusData);
    socket.on('receiveRelayData', handleRelayData);

    return () => {
      socket.off('receiveSensorData');
      socket.off('receiveStatusSensorData');
      socket.off('receiveStatusData');
      socket.off('receiveRelayData');
      socket.emit('leave_room', roomName);
    };
  }, [roomName, socket]);

  const handleJoinRoom = () => {
    socket.emit('join_room', roomName);
    socket.emit('join_room', 'modela.mobileapp@gmail.com');
  };

  const handleLeaveRoom = () => {
    socket.emit('leave_room', roomName);
    socket.emit('leave_room', 'modela.mobileapp@gmail.com');
  };

  useEffect(() => {
    getDevice();
  }, []);

  console.log('values : ', values);
  console.log("Device data:", devices);

  return (
    <div className="sensor-dashboard">
      <div className="sensor-header">
        <h2>Sensor Dashboard (
          <span style={{ color: status.Status === 'online' ? 'green' : 'grey' }}>
            {roomName} : {status.Status}
          </span>)
        </h2>
        {/* <input type="text" value={roomName} onChange={(e) => setRoomName(e.target.value)} placeholder="Enter Room Name" /> */}
        <select id="device-select" value={roomName} onChange={e => setRoomName(e.target.value)}>
          <option value="">--Please choose an device--</option>
          <option value="bBPdgbe8tzK1ZTvOwca9">Device 1: TestApp</option>
          <option value="aoeHJC5ZvRjCcRZTrwLF">Device 2: Modela2</option>
        </select>
        <button onClick={(handleJoinRoom)}>Join Room</button>
        <button onClick={(handleLeaveRoom)}>Leave Room</button>
      </div>
      <div className="sensor-data">
        {Object.entries(switches).map(([key, value], index) => (
          <div key={key}>Switch{index + 1}: <button onClick={() => toggleSwitch(index + 1)} className={value ? 'on' : 'off'}>{value ? 'ON' : 'OFF'}</button></div>
        ))}
        {/* {Object.entries(values).map(([key, value], index) => (
          <div key={key}>
            <select value={sensorSelections[key]} onChange={(e) => handleSensorSelection(key, e.target.value)}>
              {sensorOptions.map(option => (
                <option key={option.id} value={option.id}>{option.name}</option>
              ))}
            </select>
            {key} : {value}
          </div>
        ))} */}
        {/* <div> */}
          {/* <h2>Main Values</h2>
          <ul>
            {values.mainValue.map((item) => (
              <li key={item.valueNo}>Value No: {item.valueNo} - Data: {item.valueData}</li>
            ))}
          </ul>

          <h2>Second Values</h2>
          <ul>
            {values.secondValue.map((item) => (
              <li key={item.valueNo}>Value No: {item.valueNo} - Data: {item.valueData}</li>
            ))}
          </ul> */}
        {/* </div> */}
        {values.mainValue.map((item, idx) => (
          <div key={item.valueNo}>
            <select value={sensorSelections[idx + 1]} onChange={(e) => handleSensorSelection(idx + 1, e.target.value)}>
              {sensorOptions.map(option => (
                <option key={option.id} value={option.id}>{option.name}</option>
              ))}
            </select>
            <ul>
              <li key={item.valueNo}>mainValue {idx + 1} : {item.valueData}</li>
              <li key={values.secondValue[idx].valueNo}>secondValue {idx + 1} : {values.secondValue[idx].valueData}</li>
            </ul>
          </div>
        ))}
        {/* {devices.map((device) => (
          <div key={device.id}>
            <h2>{device.name}</h2>
            <ul>
              {device.sensors.map((sensor, idx) => (
                <li key={sensor.id}>
                  Sensor {idx + 1} : {sensor.name} - {sensor.value}
                </li>
              ))}
            </ul>
          </div>
        ))} */}
      </div>
    </div>
  );  
}

export default TestSocket;
